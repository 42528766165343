import React from "react";
import { NO_IMAGE_URL } from "../constants"
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames"
import { compose } from "redux";
import { amountFormatter } from "../constants/functions"

import "./one-player.scss"

function OnePlayer(props) {
  // console.log("props", props)
  const {
    onePlayer:
    {
      name,
      // surname,
      rating = 9999,
      id,
      image = "",
      amplua = "amplua",
      place = 888,
      wins,
      losses,
    },
    className,
    isCurrentUser,
    showStatisticPoints = null
  } = props



  const imgUrl = image ? image : NO_IMAGE_URL


  const renderEventDelta = (delta) => {
    return (
      <div
        className={`${Number(delta) < 0 ? "delta-minus" : "delta-plus"}`}
      >
        <span>
          {(Number(delta) > 0 || Number(delta) === 0) && "+"}{amountFormatter(delta)}
        </span>𐤃
      </div>
    )
  }

  const renderContent = () => {
    const names = name.split(" ");

    const firstName = names[0];
    const lastName = names[1];
    return (
      <Link to={`/player/${id}`} className="player-rating-container">
        <div
          className={classNames({
            'card-body': true,
            'is-current-user': isCurrentUser
          })}
        >
          <div className="d-flex flex-column align-items-center  player-date">
            <div className="player-name-place">
              <p className="text-muted font-size-m place">#{place}</p>
              <div className="player-url">
                <h4 className="last-name">{lastName}</h4>
                <h4 className="first-name">{firstName} </h4>
              </div>
            </div>

            <div
              className={classNames({
                'player-photo': true,
                'b-cotainer': !image
              })}

              style={{ backgroundImage: `url(${imgUrl})` }}>

              <div className="photo-gradient" />
            </div>

            <div >


              {showStatisticPoints ?
                <>
                  <p className="text-muted font-size-m place event-statistic">

                    {renderEventDelta(showStatisticPoints)}
                    <div className="stat">
                      <b>Побед: {wins}</b>
                      <b>Поражений: {losses}</b>
                      <b>Место: #{place}</b>
                    </div>
                  </p>
                </> :
                <>
                  <p className="text-muted font-size-m">R{amountFormatter(rating)}</p>
                </>}


            </div>
            <p className="text-secondary-custom mb-1 t-center amplua">{amplua || "Нет данных"}</p>
          </div>
        </div>
      </Link>
    )
  }


  return (
    <div className={className} >
      <div className="products">
        {renderContent()}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  lng: state.PrefsReducer.lng,
});

export default withRouter(compose(connect(mapStateToProps, {

}))(OnePlayer));


