import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import { onlyNumbersRegex, getDelta, amountFormatter, numberFormatter, decimalsRound } from "../../../../helpers/constants/functions"
import {
  GET_PLAYERS_LIST,
} from "../../../../helpers/constants"
import axiosFetch from "../../../../helpers/axios"
import "./styles.scss"

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



function AddResult() {

  const [playerOneScore, setPlayerOneScore] = useState(null)
  const [playerTwoScore, setPlayerTwoScore] = useState(null)
  const [playerOne, setPlayerOne] = useState(null)
  const [playerTwo, setPlayerTwo] = useState(null)
  const [error, setError] = useState("")
  const [playersList, setPlayersList] = useState([])

  const [showMatchResult, setShowMatchResult] = useState({})

  useEffect(() => {
    axiosFetch(GET_PLAYERS_LIST).then(res => {
      setPlayersList(res)
    })
  }, [])

  const showMatchResultDelta = () => {

    const winner = playerOneScore > playerTwoScore ? playerOne : playerTwo
    const loser = playerOneScore < playerTwoScore ? playerOne : playerTwo
    const delta = getDelta(winner.rating, loser.rating)

    const totalSets = Number(playerOneScore) + Number(playerTwoScore)
    const deltaForOneSet = Math.round(((Number(delta.forWin) / totalSets) * 3) * 100) / 100 
    const checkRealDelta = playerOneScore === 3 && playerTwoScore === 0 ? delta : { forWin: deltaForOneSet, forLose: -1 * deltaForOneSet }

    const matchResult = {
      playerOne: playerOne.id,
      playerTwo: playerTwo.id,
      playerOneName: playerOne.name,
      playerTwoName: playerTwo.name,
      scoreOne: playerOneScore,
      scoreTwo: playerTwoScore,
      currentRatingPlayerOne: playerOne.rating,
      currentRatingPlayerTwo: playerTwo.rating,
      deltaOne: winner.id === playerOne.id ? numberFormatter(checkRealDelta.forWin) : numberFormatter(checkRealDelta.forLose),
      deltaTwo: winner.id === playerTwo.id ? numberFormatter(checkRealDelta.forWin) : numberFormatter(checkRealDelta.forLose),
    }

    setShowMatchResult(matchResult)

  }


  const renderEventGames = () => {
    const {
      deltaOne,
      deltaTwo,
      currentRatingPlayerOne,
      currentRatingPlayerTwo,
      playerOneName,
      playerTwoName,
      scoreOne,
      scoreTwo,
    } = showMatchResult
    console.log("showMatchResult", showMatchResult)
    return (

      <Grid container spacing={1} className="result-line show-match-delta">
        <Grid item xs={5} className="t-center t-row">
          <Link to={`/player/${playerOne?.id}`}><b>({currentRatingPlayerOne}) {playerOneName}</b></Link>
          <span className={`${Number(scoreOne) < Number(scoreTwo) ? "delta-minus" : "delta-plus"}`}>({Math.round(Number(deltaOne) * 100) / 100})</span>
        </Grid>
        <Grid item xs={2} className="t-center match-score">
          <b>{scoreOne} : {scoreTwo}</b>
        </Grid>
        <Grid item xs={5} className="t-center t-row">
          <Link to={`/player/${playerTwo?.id}`}><b>({currentRatingPlayerTwo}) {playerTwoName}</b></Link>
          <span className={`${Number(scoreOne) > Number(scoreTwo) ? "delta-minus" : "delta-plus"}`}>({Math.round(Number(deltaTwo) * 100) / 100})</span>
        </Grid>

      </Grid>
    )

  }


  const samePlayer = playerOne?.id === playerTwo?.id;
  const scoresMax = playerOneScore === 3 && playerTwoScore === 3;
  const scoresLow = playerOneScore < 3 && playerTwoScore < 3;
  const noData = playerOneScore === null || playerTwoScore === null || !playerOne || !playerTwo;
  const disabled = noData || scoresLow || samePlayer || scoresMax;

  const setValue = (value = "", action) => {
    setError("")
    const isValid = onlyNumbersRegex(value);
    const lessThree = value <= 3;
    ((isValid && value === "") || lessThree) && action(Number(value))

  }

  // const setResult = (player) => {
  //   if (player === "playerOne") {
  //     setPlayerOneScore(3);
  //     setPlayerTwoScore(0);
  //   } else {
  //     setPlayerOneScore(0);
  //     setPlayerTwoScore(3)
  //   }
  // }

  return (

    <Box sx={{ flexGrow: 1, maxWidth: 800, margin: "0 auto" }}>

      <Grid container spacing={1} className="delta-container">
        <Grid item xs={5}>
          <Item>
            <Autocomplete
              disablePortal
              id="combo-box-demo-2"
              options={playersList}
              autoHighlight
              value={playerOne}
              onChange={(event, newValue) => {
                setPlayerOne(newValue);
                setError("")
              }}
              getOptionLabel={(option) => `${option.name} (${amountFormatter(option.rating)})`}
              renderInput={(params) => <TextField {...params} type="text" label="Выберите игрока" />}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  {option.name} ({amountFormatter(option.rating)})
                </Box>
              )}
            />
          </Item>
        </Grid>
        {/* <FormControl className="who-win-container">
          <FormLabel id="demo-row-radio-buttons-group-label">Кто победил</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel value="playerOne" control={<Radio />} onChange={() => setResult("playerOne")} />
            <FormControlLabel value="playerTwo" control={<Radio />} onChange={() => setResult("playerTwo")} />
          </RadioGroup>
        </FormControl> */}

        <Grid item xs={1}>
          <Item>
            <TextField
              id="outlined-basic-1"
              className="set-score"
              variant="outlined"
              value={playerOneScore}
              onChange={(e) => setValue(e.target.value, setPlayerOneScore)}
            />
          </Item>
        </Grid>
        <Grid item xs={1}>
          <Item>
            <TextField
              id="outlined-basic-2"
              className="set-score"
              variant="outlined"
              value={playerTwoScore}
              onChange={(e) => setValue(e.target.value, setPlayerTwoScore)}
            />
          </Item>
        </Grid>

        <Grid item xs={5}>
          <Item>
            <Autocomplete
              disablePortal
              id="add-result"
              options={playersList}
              autoHighlight
              value={playerTwo}
              onChange={(event, newValue) => {
                setPlayerTwo(newValue);
                setError("")
              }}
              getOptionLabel={(option) => `${option.name} (${amountFormatter(option.rating)})`}
              renderInput={(params) => <TextField {...params} type="text" label="Выберите игрока" />}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  {option.name} ({amountFormatter(option.rating)})
                </Box>
              )}
            />
          </Item>
        </Grid>
        {error && <div className="error">{error}</div>}
        <Grid item xs={12}>

          <Button
            onClick={() => showMatchResultDelta()}
            variant="contained"
            disabled={disabled}
            className="add-result-btn"
          >Просмотреть дельту

          </Button>
        </Grid>
      </Grid>

      {showMatchResult.deltaOne && showMatchResult.deltaTwo && renderEventGames()}


    </Box>
  );
}

const mapStateToProps = (state) => ({

});

export default withRouter(
  compose(
    connect(mapStateToProps, {}),
  )(AddResult)
);