import React, { useState, useEffect } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import { onlyNumbersRegex, getDelta, numberFormatter, decimalsRound } from "../../../../helpers/constants/functions"
import { PencilIcon } from "../../../../svg-icons/icon-pencil";
import { TrashIcon } from "../../../../svg-icons/icon-trash";
import {
  ADD_GAME_RESULT,
  GET_EVENT_GAMES,
  GET_PLAYERS_LIST,
  EDIT_GAME_RESULT,
  DELETE_GAME_RESULT,
  GET_EVENTS,
  CALC_EVENT_RATING
} from "../../../../helpers/constants"
import axiosFetch from "../../../../helpers/axios"
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CookieHelper from "../../../../helpers/cookie-helper";

import "./styles.scss"
 
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function AddResult() {

  const [playerOneScore, setPlayerOneScore] = useState(null)
  const [playerTwoScore, setPlayerTwoScore] = useState(null)
  const [playerOne, setPlayerOne] = useState(null)
  const [playerTwo, setPlayerTwo] = useState(null)
  const [event, setEvent] = useState(null)
  const [events, setEvents] = useState([]);
  const [deleteGameId, setDeleteGameId] = useState(null)
  const [fetchEventGames, setFetchEventGames] = useState(false)
  const [eventCalculated, setEventCalculated] = useState(false)
  const [fetchAddGame, setFetchAddGame] = useState(false)
  const [fetchCalcRating, setFetchCalcRating] = useState(false)
  const [fetchEditGame, setFetchEditGame] = useState(false)
  const [showCalcRatingModal, setShowCalcRatingModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [editData, setEditData] = useState({})
  const [editDataOld, setEditDataOld] = useState({})
  const [error, setError] = useState("")
  const [eventGames, setEventGames] = useState([])
  const [playersList, setPlayersList] = useState([])

  const tcaToken = CookieHelper("get", "tca-token");

  useEffect(() => {
    if (event?.id) {
      const formData = new FormData()
      formData.append("eventId", event.id)

      axiosFetch(GET_EVENT_GAMES, formData).then(res => {
        setEventGames(res)
        res[0] && setEventCalculated(res[0].calculated)
      })
    }

  }, [event, fetchEventGames])

  useEffect(() => {
    axiosFetch(GET_EVENTS).then(res => {
      setEvents(res)
    })
  }, [])

  useEffect(() => {
    axiosFetch(GET_PLAYERS_LIST).then(res => {
      setPlayersList(res)
    })
  }, [])

  const onAddResult = () => {
    setFetchAddGame(true)
    const winner = playerOneScore > playerTwoScore ? playerOne : playerTwo
    const loser = playerOneScore < playerTwoScore ? playerOne : playerTwo
    const delta = getDelta(winner.rating, loser.rating)

    const totalSets = Number(playerOneScore) + Number(playerTwoScore)
    const deltaForOneSet = Math.round(((Number(delta.forWin) / totalSets) * 3) * 100) / 100 
    const checkRealDelta = playerOneScore === 3 && playerTwoScore === 0 ? delta : { forWin: decimalsRound(deltaForOneSet), forLose: decimalsRound(-1 * deltaForOneSet) }

    const formData = new FormData()
    formData.append("playerOne", playerOne.id)
    formData.append("playerTwo", playerTwo.id)
    formData.append("scoreOne", playerOneScore)
    formData.append("scoreTwo", playerTwoScore)
    formData.append("currentRatingPlayerOne", playerOne.rating)
    formData.append("currentRatingPlayerTwo", playerTwo.rating)
    formData.append("scoreTwo", playerTwoScore)
    formData.append("eventId", event.id)
    formData.append("deltaOne", winner.id === playerOne.id ? numberFormatter(checkRealDelta.forWin) : numberFormatter(checkRealDelta.forLose))
    formData.append("deltaTwo", winner.id === playerTwo.id ? numberFormatter(checkRealDelta.forWin) : numberFormatter(checkRealDelta.forLose))


    axiosFetch(ADD_GAME_RESULT, formData).then((res) => {
      if (res !== "OK") {
        setError(res)
      } else {
        setFetchEventGames(!fetchEventGames)

        setPlayerOneScore("");
        setPlayerTwoScore("");
        setPlayerOne(null);
        setPlayerTwo(null);
      }
      setFetchAddGame(false)
    })
  }

  const onShowEditModal = (row) => {
    setEditData(row)
    setEditDataOld(row)
    setShowEditModal(true)
  }

  const cancelEditMode = () => {
    setEditData({})
    setEditDataOld({})
    setShowEditModal(false)
    setShowDeleteModal(false)
    setShowCalcRatingModal(false)
  }

  const onEditResult = () => {
    setFetchEditGame(true)
    const formData = new FormData()
    formData.append("id", editData.id)
    formData.append("scoreOne", editData.scoreOne)
    formData.append("scoreTwo", editData.scoreTwo)


    axiosFetch(EDIT_GAME_RESULT, formData).then((res) => {
      cancelEditMode()
      setFetchEditGame(false)
      setFetchEventGames(!fetchEventGames)

    })
  }


  const calcEventRating = () => {
    setFetchCalcRating(true)

    const formData = new FormData()
    formData.append("eventId", event.id)


    axiosFetch(CALC_EVENT_RATING, formData).then((res) => {
      cancelEditMode()
      setFetchCalcRating(false)
      setFetchEventGames(!fetchEventGames)
    })
  }

  const onShowDeleteData = (row) => {
    setDeleteGameId(row.id)
    setEditData(row)
    setShowDeleteModal(true)
  }

  const renderEventGames = () => {

    const content = eventGames.map((event, index) => {
      const {
        deltaOne,
        deltaTwo,
        currentRatingPlayerOne,
        currentRatingPlayerTwo,
        playerOne,
        playerTwo,
        scoreOne,
        scoreTwo,
      } = event
      return (

        <Grid container spacing={1} className="result-line" key={index}>
          <Grid item xs={3} className="t-center t-row">
            <b>({currentRatingPlayerOne}) {playerOne}</b>
            <span className={`${Number(scoreOne) < Number(scoreTwo) ? "delta-minus" : "delta-plus"}`}>({deltaOne})</span>
          </Grid>
          <Grid item xs={3} className="t-center match-score">
            <b>{scoreOne} : {scoreTwo}</b>
          </Grid>
          <Grid item xs={3} className="t-center t-row">
            <b>({currentRatingPlayerTwo}) {playerTwo}</b>
            <span className={`${Number(scoreOne) > Number(scoreTwo) ? "delta-minus" : "delta-plus"}`}>({deltaTwo})</span>
          </Grid>
         
          <Grid item xs={3}>
            <div className="action-icons">
              <PencilIcon
                onClick={() => onShowEditModal(event)}
                className="pencil-icon"
              />
              <TrashIcon
                onClick={() => onShowDeleteData(event)}
                className="trash-icon"
              />

            </div>
          </Grid>

        </Grid>
      )
    })

    return content
  }

  const onDeleteResult = () => {


    setFetchEditGame(true)
    const formData = new FormData()
    formData.append("id", deleteGameId)


    axiosFetch(DELETE_GAME_RESULT, formData).then((res) => {
      cancelEditMode()
      setFetchEditGame(false)
      setFetchEventGames(!fetchEventGames)

    })
  }

  const renderCalcRatingModal = () => {

    return (
      <Dialog
        open={showCalcRatingModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => cancelEditMode()}
        aria-describedby="alert-dialog-slide-description"
      >
        <div style={{ minWidth: 500 }}>
          <DialogTitle >Рассчитать рейтинг?</DialogTitle>
          <DialogContent >

          </DialogContent>
          <DialogActions >
            <Button
              onClick={() => cancelEditMode()}
              variant="contained"
            >
              Отмена
            </Button>
            <Button
              onClick={() => calcEventRating()}
              variant="contained"
              disabled={fetchCalcRating}
            // className="add-result-btn"
            >
              Рассчитать
              {fetchCalcRating && <CircularProgress className="circular-progress" size={18} />}
            </Button>


          </DialogActions>
        </div>

      </Dialog>
    )
  }


  const renderDeleteModal = () => {

    return (
      <Dialog
        open={showDeleteModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => cancelEditMode()}
        aria-describedby="alert-dialog-slide-description"
      >
        <div style={{ minWidth: 500 }}>
          <DialogTitle >Удаляем?</DialogTitle>
          <DialogContent >
            <DialogContentText id="alert-dialog-slide-description">
              <Grid container >
                <Grid item xs={6} className="edit-player-name">
                  {editData.playerOne}
                </Grid>
                <Grid item xs={6} className="edit-player-name">
                  {editData.playerTwo}
                </Grid>
              </Grid>
              <Grid container >
                <Grid item xs={6}>
                  <Item>
                    {editData.scoreOne}
                  </Item>
                </Grid>
                <Grid item xs={6}>
                  <Item>
                    {editData.scoreTwo}

                  </Item>
                </Grid>
              </Grid>

            </DialogContentText>
          </DialogContent>
          <DialogActions >
            <Button
              onClick={() => cancelEditMode()}
              variant="contained"
            //disabled={disabled || fetchAddGame}
            //className="add-result-btn"
            >
              Отмена
            </Button>
            <Button
              onClick={() => onDeleteResult()}
              variant="contained"
            //disabled={disabled || fetchEditGame}
            // className="add-result-btn"
            >
              Удалить
              {fetchEditGame && <CircularProgress className="circular-progress" size={18} />}
            </Button>


          </DialogActions>
        </div>

      </Dialog>
    )
  }

  const renderEditModal = () => {

    const scoresMax = editData.scoreOne === 3 && editData.scoreTwo === 3;
    const scoresLow = editData.scoreOne < 3 && editData.scoreTwo < 3;
    const disabled = JSON.stringify(editData) === JSON.stringify(editDataOld) || scoresMax || scoresLow
    return (
      <Dialog
        open={showEditModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => cancelEditMode()}
        aria-describedby="alert-dialog-slide-description"
      >
        <div >
          <DialogTitle >Редактирование игры</DialogTitle>
          <DialogContent >
            <DialogContentText id="alert-dialog-slide-description">
              <Grid container >
                <Grid item xs={6} className="edit-player-name">
                  {editData.playerOne}
                </Grid>
                <Grid item xs={6} className="edit-player-name">
                  {editData.playerTwo}
                </Grid>
              </Grid>
              <Grid container >
                <Grid item xs={6}>
                  <Item>
                    <TextField
                      id="outlined-basic-3"
                      className="set-score"
                      variant="outlined"
                      value={editData.scoreOne}
                      onChange={(e) => setEditValue(e.target.value, "scoreOne")}
                    />
                  </Item>
                </Grid>
                <Grid item xs={6}>
                  <Item>
                    <TextField
                      id="outlined-basic-4"
                      className="set-score"
                      variant="outlined"
                      value={editData.scoreTwo}
                      onChange={(e) => setEditValue(e.target.value, "scoreTwo")}
                    />
                  </Item>
                </Grid>
              </Grid>

            </DialogContentText>
          </DialogContent>
          <DialogActions >
            <Button
              onClick={() => cancelEditMode()}
              variant="contained"
            >
              Отмена
            </Button>
            <Button
              onClick={() => onEditResult()}
              variant="contained"
              disabled={disabled || fetchEditGame}
            >
              Редактировать
              {fetchEditGame && <CircularProgress className="circular-progress" size={18} />}
            </Button>


          </DialogActions>
        </div>

      </Dialog>
    )
  }


  const samePlayer = playerOne?.id === playerTwo?.id;
  const scoresMax = playerOneScore === 3 && playerTwoScore === 3;
  const scoresLow = playerOneScore < 3 && playerTwoScore < 3;
  const noData = playerOneScore === null || playerTwoScore === null || !playerOne || !playerTwo || !event;
  const disabled = noData || scoresLow || samePlayer || scoresMax;

  const setValue = (value = "", action) => {
    setError("")
    const isValid = onlyNumbersRegex(value);
    const lessThree = value <= 3;
    ((isValid && value === "") || lessThree) && action(Number(value))

  }
  const setEditValue = (value = "", state) => {
    const isValid = onlyNumbersRegex(value);
    const lessThree = value <= 3;
    ((isValid && value === "") || lessThree) && setEditData({
      ...editData,
      [state]: value
    })
    setError("")
  }

  const showCalculatedBtn = eventGames.length === 0 || fetchCalcRating || Number(eventCalculated) === 1

  return (

    <Box sx={{ flexGrow: 1, maxWidth: 800, margin: "0 auto" }}>
      {renderEditModal()}
      {renderDeleteModal()}
      {renderCalcRatingModal()}
      {!tcaToken && <Redirect to="/" />}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Item>
            <Autocomplete
              disablePortal
              id="combo-box-demo-1"
              options={events}
              autoHighlight
              value={event}
              onChange={(event, newValue) => {
                setEvent(newValue);
              }}
              getOptionLabel={(option) => `${option.name} (${option.date})`}
              renderInput={(params) => <TextField {...params} label="Выберите событие" />}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  {option.name} {option.date}
                </Box>
              )}
            />
          </Item>
        </Grid>

        <Grid item xs={5}>
          <Item>
            <Autocomplete
              disablePortal
              id="combo-box-demo-2"
              options={playersList}
              autoHighlight
              value={playerOne}
              onChange={(event, newValue) => {
                setPlayerOne(newValue);
                setError("")
              }}
              getOptionLabel={(option) => `${option.name} (${option.rating})`}
              renderInput={(params) => <TextField {...params} label="Выберите игрока" />}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  {option.name} ({option.rating})
                </Box>
              )}
            />
          </Item>
        </Grid>
        <Grid item xs={1}>
          <Item>
            <TextField
              id="outlined-basic-1"
              className="set-score"
              variant="outlined"
              value={playerOneScore}
              onChange={(e) => setValue(e.target.value, setPlayerOneScore)}
            />
          </Item>
        </Grid>
        <Grid item xs={1}>
          <Item>
            <TextField
              id="outlined-basic-2"
              className="set-score"
              variant="outlined"
              value={playerTwoScore}
              onChange={(e) => setValue(e.target.value, setPlayerTwoScore)}
            />
          </Item>
        </Grid>
        <Grid item xs={5}>
          <Item>
            <Autocomplete
              disablePortal
              id="add-result"
              options={playersList}
              autoHighlight
              value={playerTwo}
              onChange={(event, newValue) => {
                setPlayerTwo(newValue);
                setError("")
              }}
              getOptionLabel={(option) => `${option.name} (${option.rating})`}
              renderInput={(params) => <TextField {...params} label="Выберите игрока" />}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  {option.name} ({option.rating})
                </Box>
              )}
            />
          </Item>
        </Grid>
        {error && <div className="error">{error}</div>}
        <Grid item xs={12}>

          <Button
            onClick={() => onAddResult()}
            variant="contained"
            disabled={disabled || fetchAddGame}
            className="add-result-btn"
          >Добавить игру
            {fetchAddGame && <CircularProgress className="circular-progress" size={18} />}
          </Button>
        </Grid>
      </Grid>


      {!showCalculatedBtn && <Grid container spacing={1} >
        <Grid item xs={12} className="recalc-rating-btn">
          <Button
            onClick={() => setShowCalcRatingModal(true)}
            variant="contained"
            color="success"
            disabled={showCalculatedBtn}
          >
            Рассчитать рейтинг
            {fetchCalcRating &&
              <CircularProgress className="circular-progress" size={18} />
            }
          </Button>
        </Grid>
      </Grid>}
      {renderEventGames()}


    </Box>
  );
}

const mapStateToProps = (state) => ({

});

export default withRouter(
  compose(
    connect(mapStateToProps, {}),
  )(AddResult)
);