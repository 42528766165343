import React, { useState, useEffect } from "react";
import { withRouter, useParams, Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import Spinner from "../../../helpers/spinner"
import {
  GET_PLAYER_INFO,
  GET_PLAYER_CHART_STATISTIC,
  NO_IMAGE_URL,
} from "../../../helpers/constants"
import classNames from "classnames"
import { amountFormatter } from "../../../helpers/constants/functions"
import axiosFetch from "../../../helpers/axios"
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import AppMainLayout from "../../../layouts/app-main-layout"
// import moment from "moment"
import "./styles.scss"




function PlayerDetails(props) {
  const { playerId } = useParams();
  const [playerInfo, setPlayerInfo] = useState({})
  const [fetchPlayerInfo, setFetchPlayerInfo] = useState(false)
  // const [fetchGetPlayers, setFetchGetPlayers] = useState(false)
  // const [isFetching, setIsFetching] = useState(false)
  const [playerChartStatistic, setPlayerChartStatistic] = useState([])

  const [windowWidth, setWindowWidth] = useState(0);
  let resizeWindow = () => {
    setWindowWidth(window.outerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);


  useEffect(() => {

    setFetchPlayerInfo(true)
    const formData = new FormData()
    formData.append("playerId", playerId)

    axiosFetch(GET_PLAYER_INFO, formData).then(res => {
      //setPlayerName(res[0]?.playerOne || "")
      setPlayerInfo(res[0])
      document.title = `${res[0].surname} ${res[0].name} | TCA | Rating`
      setTimeout(() => {
        setFetchPlayerInfo(false)
        // setFetchGetPlayers(true)
      }, 1000);
    })

    axiosFetch(GET_PLAYER_CHART_STATISTIC, formData).then(res => {
      setPlayerChartStatistic(res)
      setTimeout(() => {
        setFetchPlayerInfo(false)
      }, 1000);
    })

  }, [playerId])

  const statisticChart = () => {
    return (
      <LineChart
        width={700}
        height={300}
        data={playerChartStatistic}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis yAxisId="left" />
        <YAxis yAxisId="right" orientation="right" />
        <Tooltip />
        <Legend />
        <Line
          yAxisId="left"
          type="monotone"
          dataKey="place"
          stroke="#15cdbc"
          name={"Место"}
          activeDot={{ r: 8 }}
        />

        <Line
          yAxisId="left"
          type="monotone"
          dataKey="wins"
          name={"Победы"}
          stroke=" #066e29"
          activeDot={{ r: 8 }}
        />
        <Line
          yAxisId="left"
          type="monotone"
          dataKey="losses"
          name={"Поражения"}
          stroke="#ff0000"
          activeDot={{ r: 8 }}
        />
        <Line
          yAxisId="left"
          type="monotone"
          dataKey="delta"
          stroke="#8884d8"
          name={"Дельта"}
          activeDot={{ r: 8 }}
        />

      </LineChart>
    )
  }

  const renderLast5MatchesWithOpponent = (matches) => {
    const content = matches.map((match, index) => {
      const { scoreOne, scoreTwo, delta, event } = match
      const color = Number(scoreOne) > Number(scoreTwo) ? "green" : "red"
      return (
        <div className={`one-match ${color}`} title={`Дельта: ${delta} / ${event}`} key={index}>
          <div />
          <span>{scoreOne}:{scoreTwo}</span>
        </div>
      )
    })
    return content
  }

  const renderLast5Games = () => {
    const content = playerInfo.last5Games.map((game, index) => {
      const { opponentName, opponentGames, opponentId } = game
      return (
        <li key={index}>
          <span className="text-secondary-custom last-match-result">

            <div className="p2p-last-5-matches">
              <div className="p2p-score">
                {opponentGames[0].scoreOne}:{opponentGames[0].scoreTwo}
              </div>
              {renderLast5MatchesWithOpponent(opponentGames)}
            </div>
          </span>
          <h6 className="mb-0">
            <Link to={`/player/${opponentId}`} >{opponentName}</Link>
          </h6>
        </li>
      )
    })

    return content
  }


  const percent = playerInfo.setWin * 100 / (playerInfo.setLose + playerInfo.setWin);
  let percentLast3 = 0
  let percentLast3Games = 0

  if (playerInfo?.last3Events) {
    percentLast3 = playerInfo?.last3Events[0].wins * 100 / (playerInfo.last3Events[0].losses + playerInfo.last3Events[0].wins);
    percentLast3Games = playerInfo?.last3Events[0].winsGames * 100 / (playerInfo.last3Events[0].lossesGames + playerInfo.last3Events[0].winsGames);
  }
  const image = playerInfo.image && playerInfo.image.length > 0 ? playerInfo.image : NO_IMAGE_URL;

  const renderAllStatistic = () => {
    return (
      <div className="card mt-3 container-romb">
        <ul className="list-group list-group-flush">
          <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
            <h6 className="mb-0">
              Сыграно турниров:
            </h6>
            <span className="text-secondary-custom">
              {playerInfo.totalEvents}
            </span>
          </li>

          <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
            <h6 className="mb-0">
              Выиграно сетов:
            </h6>
            <span className="text-secondary-custom">
              {playerInfo.setWin}
            </span>
          </li>

          <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
            <h6 className="mb-0">
              Проиграно сетов:
            </h6>
            <span className="text-secondary-custom">
              {playerInfo.setLose}
            </span>
          </li>

          <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
            <h6 className="mb-0">
              Процент успеха:
            </h6>
            <span className="text-secondary-custom ">
              {percent ? amountFormatter(percent) : 0}%
            </span>
          </li>

        </ul>
      </div>
    )
  }

  const renderStatisticFor3events = () => {
    return (
      <div className="col-sm-12 mb-3">
        <div className="card h-100">
          <div className="card-body">
            <h6 className="d-flex align-items-center mb-3">
              Статистика последних 4-х активных событий
            </h6>
            {playerInfo.last3Events && <ul className="list-widget last-3-events">
              <li >
                <h6 className="mb-0">
                  Дельта
                </h6>
                <span
                  className={`text-secondary-custom last-match-result ${Number(playerInfo.last3Events[0].delta) < 0 ? "delta-minus" : "delta-plus"}`}
                >
                  {Number(playerInfo.last3Events[0].delta) >= 0 && "+"}{playerInfo.last3Events && amountFormatter(playerInfo.last3Events[0].delta)}
                </span>
              </li>

              <li >
                <h6 className="mb-0">
                  Выиграные сеты
                </h6>
                <span className="text-secondary-custom last-match-result">
                  {playerInfo.last3Events && playerInfo.last3Events[0].wins}
                </span>
              </li>
              <li >
                <h6 className="mb-0">
                  Проигранные сеты
                </h6>
                <span className="text-secondary-custom last-match-result">
                  {playerInfo.last3Events && playerInfo.last3Events[0].losses}
                </span>
              </li>
              <li >
                <h6 className="mb-0">
                  Процент успеха по сетам:
                </h6>
                <span className="text-secondary-custom last-match-result">
                  {percentLast3 ? amountFormatter(percentLast3) : 0}%
                </span>
              </li>

              <li >
                <h6 className="mb-0">
                  Всего побед
                </h6>
                <span className="text-secondary-custom last-match-result">
                  {playerInfo.last3Events && playerInfo.last3Events[0].winsGames}
                </span>
              </li>
              <li >
                <h6 className="mb-0">
                  Всего поражений
                </h6>
                <span className="text-secondary-custom last-match-result">
                  {playerInfo.last3Events && playerInfo.last3Events[0].lossesGames}
                </span>
              </li>

              <li >
                <h6 className="mb-0">
                  Процент успеха по играм:
                </h6>
                <span className="text-secondary-custom last-match-result">
                  {percentLast3Games ? amountFormatter(percentLast3Games) : 0}%
                </span>
              </li>


            </ul>}
          </div>
        </div>
      </div>
    )
  }

  const renderPlayerLast5Games = () => {
    return (
      <div className="col-sm-12 mb-3">
        <div className="card h-100">
          <div className="card-body">
            <h6 className="d-flex align-items-center mb-3">
              Последние 7 игр
            </h6>
            <ul className="list-widget">
              {playerInfo.last5Games && renderLast5Games()}
            </ul>
          </div>
        </div>
      </div>
    )
  }


  const renderPlayerCard = () => {
    return (
      <div className="card player-card">
        <div className="card-bg" />
        <div className="card-body">
          <div className="d-flex flex-column align-items-center text-center player-data">
            {/* <img src={test} alt="Admin" className="rounded-circle w-100" width="150" /> */}
            <div
              className={classNames({
                'player-photo': true,
                'b-cotainer': !playerInfo.image
                //`${cName}`: true
              })}

              style={{ backgroundImage: `url(${image})` }}></div>
            <div className="mt-3">
              <h4>{playerInfo.surname} {playerInfo.name}</h4>
              <p className="text-secondary-custom mb-1 t-center">{playerInfo.amplua || "Нет данных"}</p>
              <div className="place-rating">
                {Number(playerInfo.visibleInRating) > 0 ?
                  <p className="text-muted font-size-m player-place-custom">#{playerInfo.place} </p> :
                  <p className="text-muted font-size-m player-place-custom no-activity">Нет активности</p>
                }
                {Number(playerInfo.visibleInRating) > 0 && <p className="text-muted font-size-m abs-rating">R{amountFormatter(playerInfo.rating)}</p>}
              </div>

              <ul className="list-group list-group-flush">
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap user-racket">
                  <h6 className="mb-0">
                    Основание:
                  </h6>
                  <span className="text-secondary-custom">
                    {playerInfo.blade || "нет данных"}
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap user-racket">
                  <h6 className="mb-0">
                    Forehand:
                  </h6>
                  <span className="text-secondary-custom">
                    {playerInfo.forhand || "нет данных"}
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap user-racket">
                  <h6 className="mb-0">
                    Backhand:
                  </h6>
                  <span className="text-secondary-custom">
                    {playerInfo.backhand || "нет данных"}
                  </span>
                </li>

                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap user-racket">
                  <div className="player-hand-hvat">
                    <div>
                      <h6 className="mb-0">
                        Хват
                      </h6>
                      <span className="text-secondary-custom">
                        {playerInfo.hvat === "euro" ? "Европейский" : "Азиатский/Перо" || "нет данных"}
                      </span>
                    </div>

                    <div>
                      <h6 className="mb-0">
                        Рабочая рука
                      </h6>
                      <span className="text-secondary-custom">
                        {playerInfo.hand === "right" ? "Правая рука" : "Левая рука" || "нет данных"}
                      </span>
                    </div>
                  </div>
                </li>
                {playerInfo.coach && <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap user-racket">
                  <h6 className="mb-0">
                    Тренер:
                  </h6>
                  <span className="text-secondary-custom">
                    {playerInfo.coach || "нет данных"}
                  </span>
                </li>}
              </ul>
              <h4 className="mb-0">Достижения</h4>
              <ul className="list-group list-group-flush ">
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap user-racket">
                  <h6 className="mb-0">
                    Максимальный рейтинг:
                  </h6>
                  <span className="text-secondary-custom stat-h4" >
                    <div className="stat-block">
                      <div>Рейтинг</div>
                      <div>{playerInfo.maxRating ? `R${amountFormatter(playerInfo.maxRating[0].bestRating)}` : "нет данных"}</div>
                    </div>
                    <div className="stat-block">
                      <div>Событие</div>
                      <div>{playerInfo.maxRating ? `${playerInfo.maxRating[0].bestEvent}` : "нет данных"}</div>
                    </div>
                    <div className="stat-block">
                      <div>Дата</div>
                      <div>{playerInfo.maxRating ? `${playerInfo.maxRating[0].bestEventDate}` : "нет данных"}</div>
                    </div>
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap user-racket">
                  <h6 className="mb-0">
                    Максимально высокое место:
                  </h6>

                  <span className="text-secondary-custom stat-h4" >
                    <div className="stat-block">
                      <div>Место</div>
                      <div>{playerInfo.maxPlace ? `#${playerInfo.maxPlace[0].bestPlace}` : "нет данных"}</div>
                    </div>
                    <div className="stat-block">
                      <div>Событие</div>
                      <div>{playerInfo.maxPlace ? `${playerInfo.maxPlace[0].bestEvent}` : "нет данных"}</div>
                    </div>
                    <div className="stat-block">
                      <div>Дата</div>
                      <div>{playerInfo.maxPlace ? `${playerInfo.maxPlace[0].bestEventDate}` : "нет данных"}</div>
                    </div>
                  </span>

                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )

  }

  const renderNavigation = () => {
    return (
      <nav aria-label="breadcrumb" className="main-breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="/">Главная</Link></li>
          <li className="breadcrumb-item">{playerInfo.surname} {playerInfo.name}</li>
        </ol>
      </nav>
    )
  }

  const renderStatisticForGames = () => {
    return (
      <div className="col-sm-12 mb-3">
        <div className="card h-100">
          <div className="card-body">
            <h6 className="d-flex align-items-center mb-3">
              Статистика по % по счетам в играх
            </h6>
            {playerInfo.last3Events && <ul className="list-widget last-3-events">
              <li >
                <h6 className="mb-0">
                  % побед со счётом 3:0
                </h6>
                <span className="text-secondary-custom last-match-result">
                  {amountFormatter(playerInfo.gamesStatistic.stat30)}%
                </span>
              </li>

              <li >
                <h6 className="mb-0">
                  % побед со счётом 3:1
                </h6>
                <span className="text-secondary-custom last-match-result">
                  {amountFormatter(playerInfo.gamesStatistic.stat31)}%
                </span>
              </li>
              <li >
                <h6 className="mb-0">
                  % побед со счётом 3:2
                </h6>
                <span className="text-secondary-custom last-match-result">
                  {amountFormatter(playerInfo.gamesStatistic.stat32)}%
                </span>
              </li>
              <li >
                <h6 className="mb-0">
                  % проигрышей со счётом 0:3
                </h6>
                <span className="text-secondary-custom last-match-result">
                  {amountFormatter(playerInfo.gamesStatistic.stat03)}%
                </span>
              </li>

              <li >
                <h6 className="mb-0">
                  % проигрышей со счётом 1:3
                </h6>
                <span className="text-secondary-custom last-match-result">
                  {amountFormatter(playerInfo.gamesStatistic.stat13)}%
                </span>
              </li>
              <li >
                <h6 className="mb-0">
                  % проигрышей со счётом 2:3
                </h6>
                <span className="text-secondary-custom last-match-result">
                  {amountFormatter(playerInfo.gamesStatistic.stat23)}%
                </span>
              </li>

              <li >
                <h6 className="mb-0">
                  Всего игр
                </h6>
                <span className="text-secondary-custom last-match-result">
                  {playerInfo.gamesStatistic.total}
                </span>
              </li>


            </ul>}
          </div>
        </div>
      </div>
    )
  }


  const renderPlayerStatisticContent = () => {
    return (
      <div className="container">
        <div className="main-body">
           {renderNavigation()}

          <div className="row gutters-sm">
            <div className="col-md-4 mb-3">
              {renderPlayerCard()}

              {renderAllStatistic()}
            </div>
            <div className="col-md-8">
              {Number(playerInfo.visibleInRating) > 0 && <div
                className={classNames({
                  'card': true,
                  'chart-container': true,
                  'd-none': windowWidth <= 500
                })}

              >
                {statisticChart()}
              </div>}
              <div className="row gutters-sm">
                {renderStatisticForGames()}
                {renderPlayerLast5Games()}
                {renderStatisticFor3events()}
              </div>
            </div>
          </div>
        </div >
      </div >
    )
  }

  return (

    <AppMainLayout>
      <div className="row">
        <div className="col-md-12">
          {fetchPlayerInfo ?
            <Spinner /> :
            renderPlayerStatisticContent()}
        </div>
      </div>
    </AppMainLayout>
  );
}

const mapStateToProps = (state) => ({

});

export default withRouter(
  compose(
    connect(mapStateToProps, {}),
  )(PlayerDetails)
);